<template>
  <div v-if="!_.isEmpty(links)">
    <div
      :class="[$style.containerGroup, open && $style.open]"
      @click="openGroup"
    >
      <div :class="$style.containerTitleNotification">
        <a :class="$style.title">
          {{ $t(`layout.sidebar.navigation.${group.name}`) }}
        </a>
        <span
          v-if="showNotificationDownload || showNotificationEmail"
          :class="[$style.notification, showNotificationEmail && $style.email]"
        />
      </div>
      <XIcon
        icon-style="fal"
        size="1x"
        icon="chevron-down"
        :class="$style.iconArrowDown"
      />
    </div>
    <div :class="[$style.listContainer, open && $style.open]">
      <ul :class="$style.list">
        <div
          v-for="(item, indexItem) in groupItems"
          :key="indexItem"
        >
          <SidebarAccordionItem
            v-if="showAccordionItem(item)"
            :item="item"
            :link="getLink(item.name)"
          />
          <!--
              NEW_MENU_AUTOMATION
              TODO: remove isToggleEnabled below
            -->
          <ul
            v-if="item?.subItems?.length && isToggleEnabled('newMenuAutomationEnabled')"
            :class="$style.subItemsList"
          >
            <XDivider
              vertical
              margin="0px"
              offset="var(--space-small-xxx)"
              color="var(--color-neutral-500)"
            />
            <div :class="$style.subItem">
              <template v-for="(subItem, indexSubItem) in item.subItems">
                <SidebarAccordionItem
                  v-if="showAccordionItem(subItem)"
                  :key="indexSubItem"
                  :item="subItem"
                  :link="getLink(subItem.name)"
                />
              </template>
            </div>
          </ul>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { useVerifyLocation } from '@account/composables';
import useGA from '@base/hooks/useGA';
import SidebarAccordionItem from './SidebarAccordionItem.vue';

export default {
  name: 'SidebarAccordionGroup',
  components: {
    SidebarAccordionItem,
  },
  props: {
    group: {
      type: Object,
      default: () => {},
    },
    bounces: {
      type: Number,
      default: 0,
    },
    downloads: {
      type: Object,
      default: () => ({}),
    },
    links: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      open: this.group.open,
      showNotificationDownload: false,
      showNotificationEmail: false,
    };
  },
  computed: {
    groupItems() {
      return this.group.items;
    },
    isNationalUser() {
      return useVerifyLocation().isNationalUser.value;
    },
    // NEW_MENU_AUTOMATION
    // TODO: remove isToggleEnabled
    isToggleEnabled() {
      return this.$store.getters['featureFlags/isToggleEnabled'];
    },
  },
  watch: {
    links: {
      handler(data) {
        this.isRouteActive(data);
      },
    },
    open: {
      handler(open) {
        const group = { name: this.group.name, open };
        this.$emit('save-group', group);
        if (open && this.showNotificationDownload) this.showNotificationDownload = false;
        if (open && this.showNotificationEmail) this.showNotificationEmail = false;
      },
    },
    downloads: {
      handler(newValue, oldValue) {
        if (this.group.name !== 'documents') return;
        if (_.isEqual(newValue.notify, oldValue.notify)) return;
        this.hasNotificationDownload();
      },
      deep: true,
    },
    bounces: {
      handler(newValue, oldValue) {
        if (this.group.name !== 'management') return;
        if (_.isEqual(newValue, oldValue)) return;
        this.hasNotificationEmail();
      },
    },
  },
  methods: {
    useGA,
    hasNotificationDownload() {
      if (!this.open && this.group.name === 'documents') {
        this.showNotificationDownload = true;
      }
    },
    hasNotificationEmail() {
      if (!this.open && this.group.name === 'management') {
        this.showNotificationEmail = true;
      }
    },
    getLink(itemName) {
      return this.links[itemName];
    },
    showAccordionItem(item) {
      // NEW_MENU_AUTOMATION
      // TODO: remove if and inner code
      if (
        this.isToggleEnabled('newMenuAutomationEnabled') &&
        ['formBuilders', 'pendingAnswers', 'flowProcesses'].includes(item.name) &&
        !item?.subItem
      ) {
        return false;
      }

      if (this.isNationalUser) return this.getLink(item.name);

      return this.getLink(item.name) && !item.hideToForeignUser;
    },
    isRouteActive(links) {
      const url = window.location.pathname;
      const urlFinded = Object.values(links).some((link) => link === url);
      if (urlFinded) this.open = true;
    },
    dispatchGA(eventAction) {
      this.useGA('event', eventAction, 'menu-app');
    },
    openGroup() {
      this.open = !this.open;
      const groups = {
        documents: 'containerGroupDocumentsClick',
        management: 'containerGroupManagementClick',
        automation: 'containerGroupAutomationClick',
        acceptance: 'list-accept-all-click',
        help: 'containerGroupHelpClick',
      };

      const eventAction = groups[this.group.name];
      this.dispatchGA(eventAction);
    },
  },
};
</script>

<style lang="scss" module>
.containerGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-regular);
  border-top: var(--border-width-small) solid var(--color-brand-support-03-500);
  cursor: pointer;

  &.open {
    background: var(--color-brand-support-03-300);

    .iconArrowDown {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background: var(--color-brand-support-03-400);

    .title,
    .iconArrowDown {
      color: var(--color-neutral-1000);
    }
  }

  .title {
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    text-transform: uppercase;
    user-select: none;
    color: var(--color-neutral-800);
  }

  .iconArrowDown {
    color: var(--color-neutral-800);
  }
}

.containerTitleNotification {
  display: flex;
  align-items: center;
}

.notification {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: var(--space-small-xx);
  background: var(--color-feedback-warning-400);
  border-radius: 50%;

  &.email {
    background: var(--color-feedback-error-400);
  }
}

.listContainer {
  overflow: hidden;
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility var(--time-transition-long), opacity var(--time-transition-long) linear,
    max-height var(--time-transition-long) ease-in-out;

  &.open {
    height: auto;
    max-height: 300px;
    visibility: visible;
    opacity: 1;
    background-color: var(--color-brand-support-03-300);
  }
}

.list {
  margin: 0;
  padding: 0;
  padding-bottom: var(--space-medium);

  .subItemsList {
    display: flex;
    margin: 0;
    padding-left: var(--space-large);
    list-style: none;

    .subItem {
      flex: 1;
    }
  }
}
</style>
