<template>
  <div :class="$style.layout">
    <LayoutDefaultSandboxBanner
      v-if="environment.sandbox"
      ref="sandboxBannerEl"
    />
    <LayoutDefaultHeaderContainer ref="headerEl" />
    <main :class="$style.main">
      <LocalRouterView v-if="$route.meta && $route.meta.rails" />
      <router-view
        v-else
        :key="$route.fullPath"
      />
    </main>
    <LayoutModalsContainer :functionality-modal="functionality" />
    <HubspotHelpForm />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';

import { openBlockModalKey } from '@base/keys';
import { useFunctionalities, useLayoutHeightCssVars, useValidateAccountActions } from '@base/hooks';
import LayoutDefaultHeaderContainer from '@base/containers/layout-default/LayoutDefaultHeaderContainer.vue';
import LayoutDefaultSandboxBanner from '@/modules/base/components/layout-default/LayoutDefaultSandboxBanner.vue';
import LayoutModalsContainer from '@/modules/base/containers/layout-default/LayoutModalsContainer.vue';
import HubspotHelpForm from '@base/components/HubspotHelpForm/HubspotHelpForm.vue';

export default defineComponent({
  name: 'BaseDefaultLayout',
  components: {
    LayoutDefaultHeaderContainer,
    LayoutDefaultSandboxBanner,
    LayoutModalsContainer,
    HubspotHelpForm,
  },
  provide() {
    return {
      [openBlockModalKey]: this.openBlockFunctionalityModal,
    };
  },

  setup() {
    const { isBlocked, isBlockedByAdmin } = useFunctionalities();
    const headerEl = ref();
    const sandboxBannerEl = ref();

    useLayoutHeightCssVars([headerEl, sandboxBannerEl]);

    return {
      isBlockedFunctionality: isBlocked,
      isBlockedByAdmin,
      headerEl,
      sandboxBannerEl,
    };
  },

  data: () => ({
    functionality: '',
  }),
  computed: {
    ...mapGetters({
      account: 'info/getAccount',
      config: 'info/getConfig',
      toggles: 'info/getToggles',
      user: 'info/getUser',
      environment: 'environment/getVariables',
      abTests: 'info/getABTests',
    }),
  },
  methods: {
    hasAnyBlock(functionality) {
      return this.isBlockedFunctionality(functionality) || this.isBlockedByAdmin(functionality);
    },
    openBlockFunctionalityModal(functionality, fn) {
      const { handleOpenActionModal } = useValidateAccountActions();

      this.functionality = functionality;

      if (this.hasAnyBlock(functionality)) {
        return handleOpenActionModal('showBlockedFunctionality');
      }

      return fn();
    },
  },
});
</script>

<style lang="scss" module>
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .main {
    display: flex;
    flex: 1;
    flex-direction: column;
    transition: all ease var(--animate-duration);
  }
}
</style>
