<template>
  <div :class="$style.layout">
    <transition
      enter-active-class="animated slideInLeft"
      leave-active-class="animated slideOutLeft"
      @leave="toggleSidebar"
      @enter="toggleSidebar"
    >
      <LayoutDefaultSidebarContainer
        v-show="openSidebar"
        :open="openSidebar"
        @close="openSidebar = false"
      />
    </transition>
    <LayoutDefaultSandboxBanner
      v-if="environment.sandbox"
      ref="sandboxBannerEl"
      :class="openSidebarStyle"
    />
    <LayoutDefaultHeaderContainer
      v-if="!$route.meta.hideHeader"
      ref="headerEl"
      :class="openSidebarStyle"
      :show-logo="false"
      show-actions
      @toggle-sidebar="openSidebar = !openSidebar"
    />
    <main :class="[$style.main, openSidebarStyle]">
      <LocalRouterView v-if="$route.meta && $route.meta.rails" />
      <router-view
        v-else
        :key="$route.meta.parent || $route.fullPath"
      />
    </main>
    <LayoutModalsContainer :functionality-modal="functionality" />
    <HubspotHelpForm />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';

import { openBlockModalKey, openBlockedByAdminModalKey } from '@base/keys';
import { useFunctionalities, useValidateAccountActions, useLayoutHeightCssVars } from '@base/hooks';
import LayoutDefaultHeaderContainer from '@base/containers/layout-default/LayoutDefaultHeaderContainer.vue';
import LayoutDefaultSidebarContainer from '@/modules/base/containers/layout-default/LayoutDefaultSidebarContainer.vue';
import LayoutDefaultSandboxBanner from '@/modules/base/components/layout-default/LayoutDefaultSandboxBanner.vue';
import LayoutModalsContainer from '@/modules/base/containers/layout-default/LayoutModalsContainer.vue';
import HubspotHelpForm from '@base/components/HubspotHelpForm/HubspotHelpForm.vue';
import eventBus from '@/bus/event-bus';

export default defineComponent({
  name: 'BaseNavigationLayout',
  components: {
    LayoutDefaultSidebarContainer,
    LayoutDefaultHeaderContainer,
    LayoutDefaultSandboxBanner,
    LayoutModalsContainer,
    HubspotHelpForm,
  },
  provide() {
    return {
      [openBlockModalKey]: this.openBlockFunctionalityModal,
      [openBlockedByAdminModalKey]: this.openBlockedByAdminModal,
    };
  },

  setup() {
    const { isBlocked, isBlockedByAdmin } = useFunctionalities();
    const headerEl = ref();
    const sandboxBannerEl = ref();

    useLayoutHeightCssVars([headerEl, sandboxBannerEl]);

    return {
      isBlockedFunctionality: isBlocked,
      isBlockedByAdmin,
      headerEl,
      sandboxBannerEl,
    };
  },

  data: () => ({
    openSidebar: true,
    functionality: '',
  }),
  computed: {
    ...mapGetters({
      account: 'info/getAccount',
      config: 'info/getConfig',
      toggles: 'info/getToggles',
      user: 'info/getUser',
      environment: 'environment/getVariables',
      abTests: 'info/getABTests',
    }),
    openSidebarStyle() {
      return this.openSidebar && this.$style.openSidebar;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleMobileScreen);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleMobileScreen);
  },
  async created() {
    this.handleMobileScreen();
  },
  methods: {
    hasAnyBlock(functionality) {
      return this.isBlockedFunctionality(functionality) || this.isBlockedByAdmin(functionality);
    },
    openBlockFunctionalityModal(functionality, fn) {
      const { handleOpenActionModal } = useValidateAccountActions();

      this.functionality = functionality;

      if (this.hasAnyBlock(functionality)) {
        return handleOpenActionModal('showBlockedFunctionality');
      }

      return fn();
    },
    openBlockedByAdminModal(functionality, fn) {
      const { handleOpenActionModal } = useValidateAccountActions();

      this.functionality = functionality;

      if (this.isBlockedByAdmin(functionality)) {
        return handleOpenActionModal('blockedByAdminModal');
      }

      return fn();
    },
    handleMobileScreen() {
      const { handleOpenActionModal } = useValidateAccountActions();
      if (window.innerWidth < 992) {
        this.openSidebar = false;
        if (sessionStorage.getItem('mobileAlert') == null) {
          handleOpenActionModal('mobileAlert');
          sessionStorage.setItem('mobileAlert', true);
        }
      } else this.openSidebar = true;
    },
    toggleSidebar() {
      eventBus.$emit('toggle-sidebar', this.openSidebar);
    },
  },
});
</script>

<style lang="scss" module>
.main {
  transition: all ease var(--animate-duration);
}

.openSidebar {
  @include breakpoint('large') {
    margin-left: 260px;
  }
}
</style>
